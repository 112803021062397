.breadcrumb-button-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons-row {
  display: flex;
  align-items: center;

  button {
    margin-left: 10px;
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  font: $h6 $font-medium;
  color: rgba(0,58,120, .85);
  cursor: pointer;

  > span:first-child:hover {
    color: rgba(0,58,120, 1);
  }

  > span:last-child {
    color: $field-color;
    cursor: text;
    opacity: 0.5;
  }
}

.common-white-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  padding: 10px 20px;
  font: $sub-text;
  background-color: white;
  border-radius: 10px;
}

.tag {
  padding: 5px 15px;
  border-radius: 5px;
}

.red-tag {
  color: $error-color;
  background-color: $error-color-light;
}

.primary-tag {
  color: $primary-color;
  background-color: $primary-color-1;
}

.secondary-tag {
  color: $secondary-color;
  background-color: $secondary-color-light;
}

.alert-blue-tag {
  color: $alert-blue;
  background-color: $alert-blue-light;
}

.disabled-icon {
  opacity: 0.5;
}

.disabled {
  color: $field-color !important;
  opacity: 0.5;
}

.disabled-control {
  border: none !important;
  -moz-appearance: window;
  -webkit-appearance: none;
}

.react-dropdown-select.disabled-control .react-dropdown-select-content {
  font-family: $font-medium !important;
}

.react-dropdown-select-type-single {
  display: flex;
  align-items: center;
  height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.confirmation-message {
  color: $field-color;
}

.ui-state-error {
  font: $text $font-regular;
  color: $error-color;
}

.white-space-pre-line {
  white-space: pre-line;
}

.select > div:nth-child(1) {
  height: 2rem;
  min-height: unset;
  padding: 2px 5px 2px 0px;
  font: $subtitle $font-regular;
  color: $field-color;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #ffffff;
  border: 0.063rem solid #e7ecf2;
  border-radius: 0.313rem;
  box-shadow: none;

  div:nth-child(1) div {
    font: $subtitle $font-regular;
    color: $field-color;
  }

  div:nth-child(2) span {
    display: none;
  }

  div:nth-child(2) div {
    padding: 0;

    svg {
      fill: #abb3ba;
    }
  }
}

.select > div:focus,
.select > div:hover {
  border: 0.063rem solid #e7ecf2;
  outline: none;
  box-shadow: none;
}

.select > div:nth-child(2) {
  width: 100%;
}

.error-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 400px;
  padding-top: 10vh;

  img {
    height: 200px;
  }
}

.invalid-url {
  margin-top: 10px;
  font: $title $font-regular;
  color: $field-color;
}

.page-does-not-exist {
  margin-top: 40px;
  font: $h4 $font-medium;
  color: $primary-color;
}

.page-not-found__back-button {
  text-decoration: none;
}

.mail-id-value {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.custom-field-title {
  position: sticky;
  top: 0;
  padding-bottom: 20px;
  font: $h5 $font-medium;
  color: $primary-color;
  background-color: #ffffff;
  z-index: 5;
}

/* Loading text three dots blinking starts here */
@keyframes blink {50% { color: transparent }}
.loader__dot { animation: 1s blink infinite }
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }
/* Loading text three dots blinking ends here */
