.table-class {
  position: relative;
  width: 100%;
  border-collapse: collapse;
  z-index: 1;

  th {
    max-width: 350px;
    position: sticky;
    height: 2.5rem;
    top: 0;
    padding: 0.313rem 1.25rem;
    font: $subtitle $font-bold;
    color: $field-color;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 2;
  }

  tbody {
    height: calc(100% - 9rem);
    box-shadow: 0 0 0 1px white;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    overflow-y: auto;
  }

  td {
    max-width: 350px;
    height: 2.5rem;
    padding: 0.4rem 1.25rem;
    font: $subtitle $font-regular;
    color: $field-color;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .link {
      color: $field-color;
      text-decoration: underline;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .badge {
      background-color: $primary-color-1-variant-color;
      color: white;
      padding: 5px;
      border-radius: 5px;
    }

    .badge.secondary {
      background-color: $secondary-color-light;
      color: $secondary-color;
    }
  }

  tr:nth-child(1) td:first-child {
    border-top-left-radius: 10px;
  }

  tr:nth-child(1) td:last-child {
    border-top-right-radius: 10px;
  }

  td.fixed-action-menu {
    overflow: visible;
  }

  .fixed-action-menu {
    position: sticky;
    right: 0;
    color: $placeholder-color;

    .material-icons-round:hover {
      color: $field-color;
    }
  }

  .fixed-action-menu-clicked {
    z-index: 1;
  }
}

.main-list-table {
  th {
    background-color: $background-color;
  }
}

.white-header-table {
  overflow-x: auto;

  th {
    background-color: white;
  }
}

.task-row {
  background-color: #ffffff !important;
  border-bottom: 2px solid $border-color;
}

.task-completed-row {
  background-color: $background-color !important;
}

.task-priority-URGENT {
  width: max-content;
  padding: 5px 12px;
  border-radius: 5px;
  background: #feebeb 0% 0% no-repeat padding-box;
  opacity: 1;
  color: $error-color !important;
  font: $text $font-medium;
}

.task-priority-LOW {
  width: max-content;
  padding: 5px 12px;
  border-radius: 5px;
  background: #e2e9f0 0% 0% no-repeat padding-box;
  opacity: 1;
  color: $primary-color !important;
  font: $text $font-medium;
}

.task-priority-HIGH {
  width: max-content;
  padding: 5px 12px;
  border-radius: 5px;
  background: #fdf0e4 0% 0% no-repeat padding-box;
  opacity: 1;
  color: $secondary-color !important;
  font: $text $font-medium;
}

.completedTask {
  background-color: $background-color !important;
  border-bottom: 2px solid $primary-color-1;
}

.table-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  max-width: 100px;
}

.table-checkbox-header {
  width: 100px;
  max-width: 100px;
  text-align: center;
}

.table-action-buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  button:not(:last-child) {
    margin-right: 7px;
  }
}

.table-class td.expandable-arrow {
  width: 24px;
  padding: 0;

  span {
    transition: all 0.3s ease-in-out;
  }
}

.rotate-expandable-arrow {
  transform: rotate(90deg);
}

.expandable-table {
  max-height: 0;
  overflow: hidden;

  > td {
    padding: 0;
    background-color: #ffffff;
    overflow: hidden;
  }

  td,
  tr,
  th,
  div {
    height: unset !important;
    max-height: 0 !important;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
  }

  th {
    height: 35px;
    padding: 9px 1.25rem;
    text-align: left;
    background-color: $background-color;
    border-top: 1px solid #ffffff;
  }

  table {
    width: 100%;
  }
}

.show-table {
  table,
  tr,
  th,
  div {
    max-height: 1000px !important;
    overflow: auto !important;
  }
}

.expandable-table th:first-child,
.expandable-table table td:first-child,
.expandable-table th:last-child,
.expandable-table table td:last-child {
  padding-left: 60px;
}

.editable-drawer-field {
  display: flex;
  align-items: center;

  .date-picker-container {
    max-width: 180px;
  }

  .react-select-container {
    min-width: 180px;
  }
}
